import React from 'react';
import { Waypoint } from 'react-waypoint';
import imagesLoaded from 'imagesloaded';

// Partials Template
import { Marquee } from './partials/Marquee';
import { About } from './partials/About';
import { Screen } from './partials/Screen';
import { Video } from './partials/Video';
import { Download } from './partials/Download';
import { Community } from './partials/Community';
import { Footer } from '../components/Footer';

export class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hero: '',
      about: '',
      screens: '',
      videos: '',
      download: '',
      community: ''
    };
  }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  componentDidMount() {
    this.imagesloaded = imagesLoaded(this.imagesloaded, { background: true });
    //埋点
    const ACTIVITY_NAME = 'wgame-official-0813';
    const event_name = 'page_view';
    const { reportH5SlsMsg } = window['metric-utils'];
    const source = this.getParameterByName('from') || '';
    reportH5SlsMsg(ACTIVITY_NAME, event_name, '', source);
    const { gtag } = window;
    gtag && gtag('event', event_name);
  }
  render() {
    return (
      <main id="home" ref={(el) => (this.imagesloaded = el)}>
        <Waypoint onEnter={() => this.setState({ hero: 'fadeInUp' })}>
          <section id="hero" className="section">
            <Marquee viewable={this.state.hero} />
            <video
              className="top_video"
              muted
              width="100%"
              height="auto"
              autoPlay
              loop
              src="https://oss-resource.farlightgames.com/p/SDK/200000/0/100000/2023-03-21/cb9b4854a5ba84837fe4c82c46a58c4e.mp4"
            ></video>
          </section>
        </Waypoint>
        <Waypoint onEnter={() => this.setState({ about: 'fadeInUp' })} bottomOffset="10%">
          <section id="about" className="section">
            <About viewable={this.state.about} />
          </section>
        </Waypoint>
        <div id="media">
          <Waypoint onEnter={() => this.setState({ screens: 'fadeInUp' })} bottomOffset="10%">
            <section id="gallery" className="section">
              <Screen viewable={this.state.screens} />
            </section>
          </Waypoint>
          <Waypoint onEnter={() => this.setState({ videos: 'fadeInUp' })} bottomOffset="10%">
            <section id="videos" className="section">
              <Video viewable={this.state.videos} />
            </section>
          </Waypoint>
        </div>
        <Waypoint onEnter={() => this.setState({ download: 'fadeInUp' })} bottomOffset="10%">
          <section id="download" className="section">
            <Download viewable={this.state.download} />
          </section>
        </Waypoint>
        <Waypoint onEnter={() => this.setState({ community: 'fadeInUp' })} bottomOffset="10%">
          <section id="community" className="section">
            <Community viewable={this.state.community} />
            <Footer />
          </section>
        </Waypoint>
        <div className="tips_video" id="tips_ytb_video">
          <div className="marks"></div>
          <div className="ytb_video_box">
            <div id="ytb_video_content" className="ytb_video_content"></div>
            <div id="ytb_close" className="ytb_close"></div>
          </div>
        </div>
      </main>
    );
  }
}
