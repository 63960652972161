import React from 'react';
import i18next from 'i18next';
import { Container, Image } from 'react-bootstrap';
import { Heading } from '../../components/Heading';
import { Content } from '../../components/Content';
import { Link } from '../../components/Link';

// Assets
import DownloadSoliderImage from '../../assets/download_soldiers.png';
import windowsLink_Bot from '../../assets/pcdown.png';

export class Download extends React.Component {
  componentDidMount() {
    var useragent = navigator.userAgent;
    useragent = useragent.toLowerCase();
    if (/iphone|ipad|ipod/.test(useragent) || /android/.test(useragent)) {
      document
        .getElementById('windows_btn_link_bot')
        .setAttribute('href', 'javascript:showpc("tips_pc_bot")');
    } else {
      document
        .getElementById('windows_btn_link_bot')
        .setAttribute(
          'href',
          'https://lead.lilithgame.com/download/game?app_id=9130660&channel_type=pc_int'
        );
    }
  }
  render() {
    const storeBtns = i18next.t('store.buttons', {
      returnObjects: true
    });
    return (
      <Container fluid>
        <div className="section-wrapper">
          <Heading
            className="display-1 text-center"
            delay="delay-3"
            title={i18next.t('download.title')}
            options={{
              animation: this.props.viewable
            }}
          />
          <div className={`overlay center inview animated ${this.props.viewable}`}>
            <Image src={DownloadSoliderImage} alt={i18next.t('download.title')} fluid />
          </div>
          <Content
            title={i18next.t('download.title')}
            content={''}
            options={{
              animation: this.props.viewable
            }}
          >
            <div className={`link-wrapper inview animated delay-4 ${this.props.viewable}`}>
              {storeBtns.map(function (button) {
                return (
                  <Link
                    key={button.title}
                    link={button.link}
                    title={button.title}
                    options={{
                      external: true,
                      rel: true
                    }}
                  >
                    <Image
                      src={require(`../../assets/${button.image}.png`)}
                      alt={button.title}
                      fluid
                    />
                  </Link>
                );
              })}
            </div>
            <div className="windows_bot animated fadeInUp">
              <div className="tips_pc" id="tips_pc_bot"></div>
              <a href="" id="windows_btn_link_bot" className="windows_btn_link">
                <Image className="windows_top_link" src={windowsLink_Bot} />
              </a>
            </div>
          </Content>
        </div>
      </Container>
    );
  }
}
