import React from 'react';
import { Navbar, Nav, Image } from 'react-bootstrap';
import smoothscroll from 'smoothscroll-polyfill';
import Logo from '../assets/logo.png';

var isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;

export class Header extends React.Component {
  constructor(props) {
    super(props);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      show: false
    };
  }
  handleToggle() {
    if (this.state.show === false) {
      this.setState({ show: true });
      document.body.classList.add('scroll-hidden');
    } else {
      this.setState({ show: false });
      document.body.classList.remove('scroll-hidden');
    }
  }
  handleClick = (e) => {
    e.preventDefault();
    // Remove all active links
    var sections = document.querySelectorAll('.nav-link');
    for (var i = 0; i < sections.length; i++) {
      sections[i].classList.remove('active');
    }
    // Add active links
    e.target.classList.add('active');
    this.setState({ show: true });
    // Toggle Close Mobile Menu
    if (e.target.className.match(/mobile/)) {
      this.handleToggle();
    }
    // Start Scrolling
    let targetId = e.target.getAttribute('href').substring(1);
    if (document.getElementById(targetId) !== null) {
      if (!isSmoothScrollSupported) {
        smoothscroll.polyfill();
      }
      window.scrollTo({
        top: document.getElementById(targetId).offsetTop,
        behavior: 'smooth'
      });
    }
    return false;
  };
  render() {
    return (
      <div>
        <nav id="expanded-menu" className={this.state.show ? 'show' : ''}>
          <div className="menu-block container">
            <a href="#home" className="nav-link mobile" onClick={this.handleClick}>
              Home
            </a>
            <a href="#about" className="nav-link mobile" onClick={this.handleClick}>
              UPDATE
            </a>
            <a href="#gallery" className="nav-link mobile" onClick={this.handleClick}>
              Gallery
            </a>
            <a href="#videos" className="nav-link mobile video" onClick={this.handleClick}>
              Video
            </a>
            <a href="#download" className="nav-link mobile" onClick={this.handleClick}>
              Download
            </a>
          </div>
        </nav>
        <Navbar expand="md" fixed="top">
          <Navbar.Brand href="#home" className={this.state.show ? 'show' : ''}>
            <Image src={Logo} alt="logo" fluid />
          </Navbar.Brand>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
            <a href="https://events-warpath.lilith.com/pcfq-en" target='_blank' className="nav-link">
              PC FAQ
            </a>
              <a href="#home" className="nav-link" onClick={this.handleClick}>
                Home
              </a>
              <a href="#about" className="nav-link" onClick={this.handleClick}>
                UPDATE
              </a>
              <a href="#gallery" className="nav-link" onClick={this.handleClick}>
                Gallery
              </a>
              <a href="#videos" className="nav-link" onClick={this.handleClick}>
                Video
              </a>
              <a href="#download" className="nav-link" onClick={this.handleClick}>
                Download
              </a>
            </Nav>
          </Navbar.Collapse>
          <button
            className={`navbar-toggler ${this.state.show ? 'show collapse' : 'collapsed'}`}
            type="button"
            onClick={this.handleToggle}
          >
            <div className="hamburger-wrapper">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>
        </Navbar>
      </div>
    );
  }
}
