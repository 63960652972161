import React from "react";
import i18next from "i18next";
import { Container, Image } from "react-bootstrap";
import { Heading } from "../../components/Heading";
import { Content } from "../../components/Content";
import { Link } from "../../components/Link";
import Swiper, { Navigation, Pagination } from "swiper";
import GLightbox from "glightbox";

// Assets
import Arrows from "../../assets/arrow.png";
import Shell from "../../assets/shell.png";
import MobileArrow from "../../assets/mobile_arrows.png";

// Install modules
Swiper.use([Navigation, Pagination]);

export class Screen extends React.Component {
  componentDidMount() {
    this.carousel = new Swiper(this.carousel, {
      loop: true,
      selector: ".swiper-slide-active .glightbox",
      centeredSlides: true,
      spaceBetween: 10,
      speed: 800,
      slideToClickedSlide: true,
      loopAdditionalSlides: 30,
      grabCursor: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        0: {
          slidesPerView: 1
        },
        768: {
          slidesPerView: "auto"
        }
      }
    });
    GLightbox();
  }
  render() {
    const mediaAssets = i18next.t("assets", {
      returnObjects: true
    });
    return (
      <Container fluid>
        <div className="shells">
          <Image src={Shell} alt={i18next.t("media.title")} />
          <Image src={Shell} alt={i18next.t("media.title")} />
        </div>
        <div className="section-wrapper">
          <Heading
            className="display-2 text-center"
            delay="delay-3"
            title={i18next.t("media.title")}
            titlesub={i18next.t("media.titlesub")}
            options={{
              animation: this.props.viewable
            }}
          />
          <Content
            delay="delay-4"
            title={i18next.t("media.title")}
            content={""}
            options={{
              animation: this.props.viewable
            }}
          />
          <div className="swiper-block">
            <div
              className="swiper-container swiper-main"
              ref={el => (this.carousel = el)}
            >
              <div className="swiper-wrapper">
                {mediaAssets.map(function(slide) {
                  return (
                    <div className="swiper-slide" key={slide.path}>
                      {slide.content ? (
                        <div className="content">{slide.content}</div>
                      ) : null}
                      <div className="inner">
                        <Link
                          link={require(`../../assets/screenshots/${slide.path}`)}
                          className="glightbox"
                          data-gallery="Screenshots"
                        >
                          <Image
                            src={require(`../../assets/screenshots/${slide.path}`)}
                            alt={slide.title}
                            fluid
                          />
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="swiper-pagination"></div>
            </div>
            <div className="swiper-button-prev">
              <Image src={Arrows} alt="Prev" fluid />
            </div>
            <div className="swiper-button-next">
              <Image src={Arrows} alt="Next" fluid />
            </div>
          </div>
          {/* <div className="bottom-arrow d-md-none">
            <Image
              src={MobileArrow}
              alt={i18next.t("media.title")}
              fluid
              className="w-100"
            />
          </div> */}
        </div>
      </Container>
    );
  }
}
