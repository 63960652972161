import React from "react";

export class Heading extends React.Component {
  render() {
    return (
      <div
        className={`heading ${
          this.props.classHeaderName ? this.props.classHeaderName : ""
        } ${this.props.options.animation}`}
      >
        <h2
          className={`title inview animated ${this.props.delay} ${this.props.className} ${this.props.options.animation}`}
        >
          {this.props.title}
          {this.props.titlesub && <span> {this.props.titlesub} </span>}
        </h2>
      </div>
    );
  }
}
