import React from 'react';
import { Container } from 'react-bootstrap';
export class About extends React.Component {
  render() {
    return (
      <Container fluid>
        <div className="section-wrapper">
          <h2 className="title inview animated delay-3 display-2 text-center fadeIn">
            Grants 5% Bonus
            <br />
            <span>Diamonds</span>
          </h2>
          {/* <Content
            className="section d-flex align-items-center container-sl"
            delay="delay-4"
            title={i18next.t("about.title")}
            content={i18next.t("about.content")}
            options={{
              animation: this.props.viewable
            }}
          /> */}
          <div className="mobile_img"></div>
        </div>
      </Container>
    );
  }
}
