import React from "react";

export class Content extends React.Component {
  render() {
    return (
      <div className={this.props.className ? this.props.className : ""}>
        <div
          className={`content-wrapper inview animated ${
            this.props.delay ? this.props.delay : "delay-1"
          } ${this.props.options.animation}`}
        >
          {this.props.content ? (
            <div className={`content`}>
              <p>{this.props.content}</p>
            </div>
          ) : null}
          {this.props.children}
        </div>
      </div>
    );
  }
}
