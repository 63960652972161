import React from 'react';
import i18next from 'i18next';
import { Container, Image, Modal, Button } from 'react-bootstrap';
import { Link } from '../components/Link';
import Cookies from 'js-cookie'
export class Hero_official extends React.Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      show: false,
      showed: false
    };
  }

  handleClose() {
    this.setState({ show: false, showed: true});
    Cookies.set('_warpath_show', '1', { expires: 1 })
  }

  handleShow() {
    if (this.state.showed) {
      window.open("https://plutomall.com/warpath/global")
    } else {
      this.setState({ show: true});
    }
  }

  componentDidMount() {
    var useragent = navigator.userAgent;
    useragent = useragent.toLowerCase();
    if (/iphone|ipad|ipod/.test(useragent) || /android/.test(useragent)) {
      document
        .getElementById('windows_btn_link_top')
        .setAttribute('href', 'javascript:showpc("tips_pc_top")');
    } else {
      document
        .getElementById('windows_btn_link_top')
        .setAttribute(
          'href',
          'https://lead.lilithgame.com/download/game?app_id=9130660&channel_type=pc_int'
        );
    }
    const _show = Cookies.get('_warpath_show')
    if (_show) {
      this.setState({ showed: true });
    } else {
      this.handleShow()
    }
  }

  render() {
    // const lgMedia = window.matchMedia(`(min-width: 768px)`);
    const storeBtns = i18next.t('store.buttons', {
      returnObjects: true
    });
    return (
      <div>
        {/* {this.props.video && lgMedia.matches ? (
          <div className="video-bg">
            <div className="video-bg-inner">
              <video
                id="video"
                loop
                autoPlay
                muted
                src={require(`../assets/${this.props.video}`)}
              />
            </div>
          </div>
        ) : null} */}
        <div className="hero-wrapper">
          <Container className="" fluid>
            <div className={`display-2 inview animated delay-1 ${this.props.options.animation}`}>
              <Image
                className="hero-wrapper-title"
                src={require(`../assets/${this.props.image}.png`)}
                alt="warpath"
                fluid
              />
              <Image
                className="hero-wrapper-title-m"
                src={require(`../assets/slogan.png`)}
                alt="warpath"
                fluid
              />
            </div>
            <div className="home_btns animated fadeInUp">
              
              <div>
                <div className="windows_top">
                  <div className="tips_pc" id="tips_pc_top"></div>
                  <a href="" id="windows_btn_link_top" className="windows_btn_link">
                    <Image className="windows_top_link" src={require(`../assets/windows_top.png`)} />
                  </a>
                </div>

                <div className={` hero-links ${this.props.options.animation}`}>
                  {storeBtns.map(function (button) {
                    return (
                      <Link
                        key={button.title}
                        link={button.link}
                        title={button.title}
                        options={{
                          external: true,
                          rel: true
                        }}
                      >
                        <Image
                          src={require(`../assets/${button.image}.png`)}
                          alt={button.title}
                          fluid
                        />
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </Container>
        </div>
        {/* {this.props.options.scroll === true ? (
          <div className="scroll-down-arrow">
            <div className="chevron"></div>
            <div className="chevron"></div>
            <div className="chevron"></div>
          </div>
        ) : null} */}

        {/* {this.state.show ? (
          <Modal
            id="mobile-video-modal"
            animation={false}
            show={this.state.show}
            onHide={this.handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
          >
            <Modal.Body>
            <Image
                className="hero-wrapper-title-m"
                src={require(`../assets/pay_modal.jpg`)}
                alt="warpath"
                fluid
              />
                 <a href="https://plutomall.com/warpath/global" target="_blank">
                    <Image src={require(`../assets/btn_go.png`)} fluid className="link_to_pay"
                    />
                  </a>
                     <Button
                className="modal-video-close-btn"
                onClick={this.handleClose}
              ></Button>
            </Modal.Body>
          </Modal>
        ) : null} */}

      </div>
    );
  }
}
