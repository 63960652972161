import React from 'react';
import i18next from 'i18next';
import { Container, Image, Modal, Button } from 'react-bootstrap';
import { Link } from '../components/Link';
import Cookies from 'js-cookie'
export class Hero extends React.Component {

  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.getParameterByName = this.getParameterByName.bind(this);
    this.state = {
      show: false,
      showed: false,
      navLang: '',
    };
  }

  handleClose() {
    this.setState({ show: false, showed: true });
    Cookies.set('_warpath_show', '1', { expires: 1 })
  }

  handleShow() {
    if (this.state.showed) {
      window.open("https://store.lilith.com/warpath/global?shortlink=wgg.en.web.os.ce.en")
    } else {
      this.setState({ show: true });
    }
  }

  handleClick() {
    const ACTIVITY_NAME = 'wgame-official-0813';
    const event_name = 'banner_click';
    const { reportH5SlsMsg } = window['metric-utils'];
    const source = this.getParameterByName('from') || '';
    reportH5SlsMsg(ACTIVITY_NAME, event_name, '', source);
    const { gtag } = window;
    gtag && gtag('event', event_name);
  }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  componentDidMount() {
    let useragent = navigator.userAgent;
    const lg = navigator.language || navigator.userLanguage;
    console.log('当前语言代码：',lg);
    const langMap = {
      'ar': ['ar'],
      'de': ['de-DE'],
      'en': ['en'],
      'es': ['es-ES'],
      'fr': ['fr-CA'],
      'id': ['id-ID'],
      'it': ['it-IT'],
      'ja': ['ja'],
      'ko': ['ko-KR'],
      'ms': ['ms-MY', 'ms'],
      'pl': ['pl-PL'],
      'pt': ['pt-PT', 'pt-BR'],
      'ru': ['ru'],
      'th': ['th-TH'],
      'tr': ['tr-TR'],
      'vi': ['vi-VN'],
      'zh-TW': ['zh-TW']
    }
    const langKeysList = Object.keys(langMap)
    let navLang = 'en'
    for(let i = 0; i < langKeysList.length; i++) {
      const lang = langKeysList[i]
      langMap[lang].push(lang)
      const list = langMap[lang]
      if(list.includes(lg)) {
        navLang = lang
        break
      }
    }
    this.setState({ navLang: navLang });
    useragent = useragent.toLowerCase();
    if (/iphone|ipad|ipod/.test(useragent) || /android/.test(useragent)) {
      document
        .getElementById('windows_btn_link_top')
        .setAttribute('href', 'javascript:showpc("tips_pc_top")');
    } else {
      document
        .getElementById('windows_btn_link_top')
        .setAttribute(
          'href',
          'https://lead.lilithgame.com/download/game?app_id=9130660&channel_type=pc_int'
        );
    }
    // const _show = Cookies.get('_warpath_show')
    // if (_show) {
    //   this.setState({ showed: true });
    // } else {
    //   this.handleShow()
    // }

    this.setState({ show: true });
  }

  render() {
    // const lgMedia = window.matchMedia(`(min-width: 768px)`);
    const storeBtns = i18next.t('store.buttons', {
      returnObjects: true
    });
    return (
      <div>
        {/* {this.props.video && lgMedia.matches ? (
          <div className="video-bg">
            <div className="video-bg-inner">
              <video
                id="video"
                loop
                autoPlay
                muted
                src={require(`../assets/${this.props.video}`)}
              />
            </div>
          </div>
        ) : null} */}
        <div className="hero-wrapper">
          <a href="https://store.lilith.com/warpath/global?shortlink=wgg.en.web.os.ce.en" target="_blank" className="pay_btn_link_fix">
            <Image src={require(`../assets/btns/a_pay_btn2.png`)} />
          </a>
          <Container className="" fluid>
            <div className={`display-2 inview animated delay-1 ${this.props.options.animation}`}>
              <Image
                className="hero-wrapper-title"
                src={require(`../assets/${this.props.image}.png`)}
                alt="warpath"
                fluid
              />
              <Image
                className="hero-wrapper-title-m"
                src={require(`../assets/slogan.png`)}
                alt="warpath"
                fluid
              />
            </div>
            <div className="home_btns animated fadeInUp">
              <div onClick={this.handleShow} className="pay_btn_link">
                <Image src={require(`../assets/btns/a_pay_btn.png`)} />
              </div>
              <div>
                <div className="windows_top">
                  <div className="tips_pc" id="tips_pc_top"></div>
                  <a href="" id="windows_btn_link_top" className="windows_btn_link">
                    <Image className="windows_top_link" src={require(`../assets/windows_top.png`)} />
                  </a>
                </div>

                <div className={` hero-links ${this.props.options.animation}`}>
                  {storeBtns.map(function (button) {
                    return (
                      <Link
                        key={button.title}
                        link={button.link}
                        title={button.title}
                        options={{
                          external: true,
                          rel: true
                        }}
                      >
                        <Image
                          src={require(`../assets/${button.image}.png`)}
                          alt={button.title}
                          fluid
                        />
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </Container>
        </div>
        {/* {this.props.options.scroll === true ? (
          <div className="scroll-down-arrow">
            <div className="chevron"></div>
            <div className="chevron"></div>
            <div className="chevron"></div>
          </div>
        ) : null} */}
        {this.state.show ? (
          <Modal
            id="mobile-video-modal"
            animation={false}
            show={this.state.show}
            onHide={this.handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
          >
            <Modal.Body>
              <Image
                className="hero-wrapper-title-m"
                src={require(`../assets/banner/${this.state.navLang}.jpg`)}
                alt="warpath"
                fluid
              />
              <a href="https://store.lilith.com/warpath/global?tab=score&shortlink=wgg.int.web.os.po.en" target="_blank" onClick={this.handleClick}>
                <Image src={require(`../assets/banner_btn/${this.state.navLang}.png`)} fluid className="link_to_pay_bottom" />
              </a>
              <Button
                className="modal-video-close-btn"
                onClick={this.handleClose}
              ></Button>
            </Modal.Body>
          </Modal>
        ) : null}
      </div>
    );
  }
}
