import React from 'react';
import i18next from 'i18next';
import { Container, Image, ResponsiveEmbed } from 'react-bootstrap';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Assets
import Shell from '../../assets/shell.png';
import ArrowRight from '../../assets/down_arrows_right.png';

import PlayBtn from '../../assets/playBtn.png';
import YTvideo1 from '../../assets/video_01.png';
import YTvideo2 from '../../assets/video_02.png';

const isiPad =
  (/iPad/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
  !window.MSStream;

export class Video extends React.Component {
  constructor(props) {
    super(props);
    this.player = '';
    this.state = { active: '', player: '' };
    this.lgMedia = window.matchMedia(`(min-width: 768px)`);
    this.video = i18next.t('videos.url') + '&enablejsapi=1';
    this.videoCode = i18next.t('videos.code');
    this.videoReady = false;
  }
  toggleClass() {
    this.setState({
      active: 'active'
    });
    this.player.playVideo();
  }
  componentDidMount() {
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      window.onYouTubeIframeAPIReady = this.loadVideo;
    }
    var ytbSTR = '';
    let elementv1 = document.getElementById('videoYT1');
    let elementv2 = document.getElementById('videoYT2');
    let tips_ytb_video = document.getElementById('tips_ytb_video');
    let ytb_video_content = document.getElementById('ytb_video_content');
    let ytb_close = document.getElementById('ytb_close');

    elementv1.onclick = () => {
      tips_ytb_video.style.display = 'block';
      ytb_video_content.innerHTML =
        '<iframe id="youtube-player-k10aNCsDWEk" src="https://www.youtube.com/embed/_FsCbnEdKo0?rel=0&amp;enablejsapi=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen="" title="Warpath: Official Trailer"></iframe>';
    };
    elementv2.onclick = () => {
      tips_ytb_video.style.display = 'block';
      ytb_video_content.innerHTML =
        '<iframe id="youtube-player-k10aNCsDWEk" src="https://www.youtube.com/embed/HxdV13Hj8mc?rel=0&amp;enablejsapi=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen="" title="Warpath: Official Trailer"></iframe>';
    };
    ytb_close.onclick = () => {
      ytb_video_content.innerHTML = '';
      tips_ytb_video.style.display = 'none';
    };
  }
  componentWillUnmount() {
    window.onscroll = null;
  }
  loadVideo = () => {
    this.player = new window.YT.Player(`youtube-player-${this.videoCode}`, {
      events: {
        onReady: this.onPlayerReady
      }
    });
  };
  onPlayerReady = () => {
    this.setState({
      player: 'on'
    });
    window.onscroll = () => {
      if (this.player) {
        let elementTarget = document.getElementById('videos');
        let elemHeightBottom = elementTarget.offsetTop + 400;
        let elemHeightTop = elementTarget.offsetTop - 800;
        if (window.scrollY > elemHeightBottom || window.scrollY < elemHeightTop) {
          this.player.pauseVideo();
        }
      }
    };
    window.onresize = () => {
      if (this.player) {
        this.player.pauseVideo();
      }
    };
  };
  onPlayerStateChange = () => {};
  render() {
    return (
      <Container>
        <div className="shells">
          <Image src={Shell} alt={i18next.t('media.title')} />
          <Image src={Shell} alt={i18next.t('media.title')} />
          <Image src={Shell} alt={i18next.t('media.title')} />
        </div>
        <div className="overlay right">
          <Image src={ArrowRight} alt={i18next.t('media.title')} fluid />
        </div>
        <div className="section-wrapper">
          <h2 className="title inview animated delay-3 display-1 text-center fadeInUp">
            Take aim and open fire
          </h2>
          <div className={`video-container ${this.lgMedia.match ? 'd-none' : ''}`}>
            {
              /* <ResponsiveEmbed aspectRatio="16by9">
              <div
                className={`iframe-wrapper ${this.state.player} ${
                  this.state.active
                } ${isiPad ? "isiPad" : ""}`}
              >
                <div className="spinner ddd">
                  <FontAwesomeIcon icon={faCircleNotch} spin size="5x" />
                </div>
                <div className="playbtn" onClick={this.toggleClass.bind(this)}>
                  <Image src={PlayBtn} fluid />
                </div>
                <iframe
                  id={`youtube-player-${this.videoCode}`}
                  src={this.video}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  title={i18next.t("media.title")}
                />
              </div>
            </ResponsiveEmbed> */
              <ul className="video_list animated delay-4 fadeInUp">
                <li id="videoYT1" data-vid="_FsCbnEdKo">
                  <Image src={YTvideo1} />
                  <span>
                    <Image src={PlayBtn} />
                  </span>
                </li>
                <li id="videoYT2" data-vid="HxdV13Hj8mc">
                  <Image src={YTvideo2} />
                  <span>
                    <Image src={PlayBtn} />
                  </span>
                </li>
              </ul>
            }
            <div className="mobile_next"></div>
          </div>
        </div>
      </Container>
    );
  }
}
